<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class="row">
      <div class="flex xs12">
        <team-bar
          :loading="loading"
          :disabled="loading"
          :with-filters="teamFilter"
          :initial-team="initialTeam"
          @selected-team="teamSelected"
        />
      </div>
      <div class="flex xs12 md6">
        <va-input
          color="info"
          v-model="form.request.value"
          type="textarea"
          :autosize="true"
          :min-rows="5"
          :disabled="loading"
          :label="$t('layout.form.requestInput')"
          :error="!!form.request.errors.length"
          :error-messages="translatedErrors(form.request.errors)"
          @blur="validate('request')"
        />
      </div>
      <div class="flex xs12 md6">
        <h5>{{$t('reports.modal.images')}}</h5>
        <div class="flex xs12">
          <va-file-upload
            dropzone
            type="gallery"
            v-model="gallery"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'

const TeamBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Team')

export default {
  name: 'inventory-requests-form',
  components: {
    TeamBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    request: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      gallery: [],
      teamFilter: '',
      initialTeam: null,
      selectedTeam: null,
      form: {
        request: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentLocale', 'currentUser']),
  },
  watch: {
    request (val) {
      this.setInventoryRequest(val)
    },
    async currentLocale (val) {
      await this.$nextTick()
      this.validateAll()
    },
    gallery (val) {
      this.filterGallery(val)
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setInventoryRequest (val) {
      this.setFormData(val)

      if (val.team) {
        this.initialTeam = val.team
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.request) {
        this.setInventoryRequest(this.request)
      }
    },
    teamSelected (team) {
      this.selectedTeam = team
    },
    filterGallery (gallery) {
      const maxFileSize = 8388608
      const fileTypes = ['image/gif', 'image/png', 'image/jpeg']
      const currentGallery = gallery.filter(g => {
        const size = g.size <= maxFileSize
        const type = fileTypes.findIndex(t => t === g.type) !== -1
        return size && type
      })

      if (currentGallery.length !== gallery.length) {
        const types = '.png, .jpg, .gif'
        const size = '8MB'
        this.showToast(this.$t('layout.images.not_allowed', { types, size }), {
          icon: 'fa-times',
          duration: 3000,
          position: 'top-right',
        })
        this.gallery = currentGallery.slice(0)
      }
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const request = new FormData()
      if (this.request.id) {
        request.append('id', this.request.id)
      }

      if (!request.user_id) {
        request.append('user_id', this.currentUser.id)
      }
      if (this.selectedTeam) {
        request.append('team_id', this.selectedTeam.id)
      } else {
        this.showToast(this.$t('notifications.auth.team_needed'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        return
      }

      const requestData = this.getFormData(this.request)
      request.append('request', requestData.request)

      for (const image of this.gallery) {
        request.append('inventory_requests_images[]', image)
      }
      this.$emit('submit', request)
    },
  },
}
</script>
